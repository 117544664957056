import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import GSTImg from '../media/GST.png';
import {
  Link
} from "react-router-dom";

export default function FirmDeed() {


    window.scrollTo(0,0);

  return (
    <>
      <Container lg='12' fluid >
        <Row>

          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>

              <Row >
                <Col lg='9'>
                    <h2>Firm Deed</h2>
                  <span class="headingCommon">Everything you need to know about Firm Deed. We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>A Firm Deed is a written instrument made between two or more person who is obliged to carry on the business under the name of the partnership firm. It is the most important document which conveys the terms & conditions of the firm. It also mentions the duties, rights, liabilities & other business-related details. This is the document on which all the functions of the business among all the partners have been decided.</p></span>
                  <p className="spacer"></p>
                  <p>
                    Number of days to process: <b>-</b> |  <b>Charges : Rs. 2199/-</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                  <br></br>
                  <p>
                    <span className="headingCommon">Eligibility for Udyog Aadhar : <p className="text-muted">Each kind of business element is qualified to acquire the Udyog Aadhar:</p></span>
                    <ul className="docList">
                      <li>1. Proprietorship.</li>
                      <li>2. One Person Company (OPC).</li>
                      <li>3. Hindu Undivided Family (HUF).</li>
                      <li>4. Limited liability partnership (LLP).</li>
                      <li>5. Private limited or limited company.</li>
                      <li>6. Co-operative Societies or Any association of persons.</li>
                    </ul>

                  </p>
                  <p className="headingCommon">Benefits of having Udyog Aadhar</p>
                  <ul className="docList">
                    <li>1. Excise exemption</li>
                    <li>2. The exemption under the direct tax laws</li>
                    <li>3. Credit guarantee scheme.</li>
                    <li>4. Protection from delayed payments.</li>
                    <li>5. Concession in electricity bills.</li>
                    <li>6. 50% reduction in fee for filing patents and trademarks.</li>
                    <li>7. Loans without guarantee, low-interest rates on loan.</li>
                    <li>8. Subsidies provided to increase the company's revenue.</li>
                    <li>9. Financial support for participating in foreign business exposure from the Government of India.</li>
                    <li>10. The exemption provided when applying for government tenders.</li>
                    <li>11. Octroi benefits.</li>
                  </ul>
                </Col>
                <Col lg='3'><img src={GSTImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}