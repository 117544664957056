import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import GSTImg from '../media/iec.png';
import {
  Link
} from "react-router-dom";

export default function ImportExport() {


    window.scrollTo(0,0);

  return (
    <>
      <Container lg='12' fluid >
        <Row>

          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>

              <Row >
                <Col lg='9'>
                    <h2>Apply for Import Export Code</h2>
                  <span class="headingCommon">Everything you need to know about Import Export Code (also known as IEC). We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>Import Export Code (also known as IEC) is a 10 digit identification number that is issued by the DGFT (Director General of Foreign Trade), Department of Commerce, Government of India. It is also known as Importer Exporter Code. Companies and businesses must obtain this code to start a business that deals with import and export in the Indian Territory. It is not possible to deal with export or import business without this code.</p></span>
                  <p className="spacer"></p>
                  <p>
                    Number of days to process: <b>1 Hour *</b> |  <b>Charges : Rs. 1,799/-</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                  <br></br>
                  
                </Col>
                <Col lg='3'><img src={GSTImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}