import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ServiceImg from '../media/FSSAI.png';
import {
  Link
} from "react-router-dom";  

export default function FSSAI() {


  window.scrollTo(0, 0);

  return (
    <>
      <Container lg='12' fluid >
        <Row>

          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>
              <Row >
                <Col lg='9'>
                  <h2>Food Safety and Standards Authority of India (FSSAI) - Basic for 5 years</h2>
                  <span class="headingCommon">Everything you need to know about FSSAI. We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>Food Safety and Standards Authority of India (FSSAI) is an autonomous body established under the Ministry of Health & Family Welfare, Government of India. The FSSAI has been established under the Food Safety and Standards Act, 2006, which is a consolidating statute related to food safety and regulation in India. FSSAI is responsible for protecting and promoting public health through the regulation and supervision of food safety.</p></span>
                  <p className="spacer"></p>
                  <p>
                    Number of days to process: <b>Depends on number of applications *</b> |  <b>Charges : Rs. 1,499/-</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                  <br></br>
                 
                </Col>
                <Col lg='3'><img src={ServiceImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}