import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import GSTImg from '../media/GST.png';
import {
  Link
} from "react-router-dom";

export default function GstInformation() {

  window.scrollTo(0, 0);
  return (
    <>
      <Container lg='12' >
        <Row>
          <Col>
            <Container lg='12' className="serviceGST" >
              <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>

              <Row >

                <Col lg='9'>
                  <span class="headingCommon">Everything you need to know about GST. We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>Goods and Services Tax is an indirect tax used in India on the supply of goods and services. The existing state and
central indirect taxes are under the GST. GST is applicable PAN INDIA (except Jammu and Kashmir). It is famous for "One Nation One Tax".</p></span>

                  <p>
                    Number of days to process: <b>4-5 Days *</b> |  <b>Charges : Rs.799/-</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                  <br></br>
                  <p>
                    <span class="headingCommon">GST Registration Certificate Package includes:   </span>
                    <ul className="docList">
                      <li>1. GSTIN Number.</li>
                      <li>2. PAN Number of the business entity.</li>
                      <li>3. Legal and Trade Names of the business.</li>
                      <li>4. Service Tax Registration Number.</li>
                    </ul>

                  </p>
                  <p class="headingCommon">GST Certificate registration document list.</p>
                  <ul className="docList">
                    <li>1. Latest Photograph.</li>
                    <li>2. ID Proof (You can submit any two).</li>
                    <ul>
                      <li>a. Aadhar Card</li>
                      <li>b. Election Card</li>
                      <li>c. Passport</li>
                      <li>d. PAN Card (Compulsory)</li>
                    </ul>
                  </ul>
                </Col>
                <Col lg='3'><img src={GSTImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}