import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Gmap from "./Gmap";

export default function Contact() {

  return (
    <>
   <Row>
        <Col>
          <Container className='sectionContact' fluid>
       
            <Row>
              <Col>
                <h3>We are call away or email us in detail.</h3>
                <p>We are happy to assist you in getting you legal documents on track. We will asure you the best possible service with amazing turn around time.</p>
                <br></br>

                <i class="fi-rr-smartphone"></i> <p>Email us on : <a href="mailto:ceo@breakcomfort.com?cc=sagsentrepreneur@gmail.com&subject=Enquiry">ceo@breakcomfort.com </a><br></br> Call us Today : <a href="tel:919867008910">+91 9867008910</a> </p> <br></br><br></br>
              </Col>
              
            </Row>
           

          </Container>
        </Col>

      </Row>

    </>
  );
}