import wp from '../media/svg/whatsapp-brands.svg';
import fb from '../media/svg/facebook-brands.svg';
import insta from '../media/svg/instagram-brands.svg';
import yt from '../media/svg/youtube-brands.svg';


import logo from '../media/BreakComfort-logo-PNG.png';

import React from 'react'
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart  } from '@fortawesome/free-solid-svg-icons'
import {

    Link
} from "react-router-dom";

export default function Footer() {

    return (
        <>
            <Container className="sectionFooter" fluid>
                <Row>
                    <Col>
                        <img src={logo} className="logoFooter" alt="logo" />
                    </Col>
                    <Col md="2">
                        <h5>BreakComfort<sup>&trade;</sup></h5>
                        <p className='ptext'>We are here to assist you in every step of your legal documentation!</p>
                        <p className='ptext2'>We are based in MUMBAI</p>
                    </Col>
                    <Col md="4" >
                        <h6 >Quick Links</h6>
                        <p className='ptext2 menuUL' >
                            <ul>

                                <Link to="/about" style={{ margin: '0 auto' }} >
                                    <li>About</li>
                                </Link>
                                <Link to="/services" style={{ margin: '0 auto' }} >
                                    <li>Services</li>
                                </Link>

                                <Link to="/contact" style={{ margin: '0 auto' }} >
                                    <li>Contact</li>
                                </Link>
                              
                            </ul>


                        </p>
                    </Col>
                    <Col md="4">
                        <h5>Social Reach</h5>
                        <a href="https://wa.me/919867008910" alt="" target="_blank"  rel="noreferrer"> <img src={wp} alt="logo" style={{paddingRight:'9px',width:'35px'}}/></a>
                     <a href="https://www.facebook.com/BreakComfort/" alt="" target="_blank"  rel="noreferrer">  <img src={fb} alt="logo" style={{paddingRight:'9px',width:'35px'}}/></a>
                     <a href="https://www.instagram.com/breakcomfort20/" alt="" target="_blank"  rel="noreferrer"> <img src={insta} alt="logo" style={{paddingRight:'9px',width:'35px'}}/></a>
                     <a href="https://youtube.com/channel/UC4pAatBBxNYmfhCKNEl3rAQ" alt="" target="_blank"  rel="noreferrer"><img src={yt} alt="logo" style={{width:'35px'}}/> </a>
                 
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='copyText'>© Copyrights with BreakComfort <sup>&trade;</sup></p>
                    </Col>

                    <Col>
                        <p className='popkornlab'>Made in INDIA with <FontAwesomeIcon icon={faHeart} color="red" size='1x' /> | Developed by <a href="#" target="_blank" title="Visit Popkornlab" rel="noreferrer">Popkornlab</a></p>
                    </Col>
                   
                    
                  
                </Row>

            </Container>

        </>
    );
}