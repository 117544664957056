import React from "react";
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Component } from "react";


const mapStyles = {
    width: '100%',
    height: '100%'
  };



export class GoogleMap extends Component {
  
    render() {
        return (
          <Map
            google={this.props.google}
            zoom={14}
            style={mapStyles}
            initialCenter={
              {
                lat: -1.2884,
                lng: 36.8233
              }
            }
          />
        );
      }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCTGOWPWRLMXT56YY6lqt3sYNb4LHUikvU'
})(GoogleMap);