import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ServiceImg from '../media/pf.png';
import {
  Link
} from "react-router-dom";  

export default function PF() {


  window.scrollTo(0, 0);

  return (
    <>
      <Container lg='12' fluid >
        <Row>

          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>
              <Row >
                <Col lg='9'>
                  <h2>Provident Fund (PF) Withdrawal</h2>
                  <span class="headingCommon">Everything you need to know about Provident Fund(PF). We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>A provident fund is a government-managed, essential retirement fund saving scheme in India. The employee gives a portion of their salary to the Provident fund and an employer (Company) contributes the same portion on behalf of the employee. The PF can be withdrawn or transferred in case you change your existing job.</p></span>
                  <p className="spacer"></p>
                  <p>
                    Number of days to process: <b>Depends on the process</b> |  <b>Charges : Rs.7999/-</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                  <br></br>
                 
                </Col>
                <Col lg='3'><img src={ServiceImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}