import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ServiceImg from '../media/pan.png';
import {
  Link
} from "react-router-dom";

export default function Pan() {


    window.scrollTo(0,0);

  return (
    <>
      <Container lg='12' fluid >
        <Row>

          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>

              <Row >
                <Col lg='9'>
                    <h2>Permanent Account Number (PAN)</h2>
                  <span class="headingCommon">Everything you need to know about Permanent Account Number (PAN). We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>A permanent account number (PAN) is a ten-character alphanumeric identifier, issued in the form of a laminated "PAN card", by the Indian Income Tax Department, to any "person" who applies for it or to whom the department allots the number without an application. It can also be obtained in the form of a PDF file.
A PAN is a unique identifier issued to all judicial entities identifiable under the Indian Income Tax Act, 1961. The income tax PAN and its linked card are issued under Section 139A of the Income Tax Act. It is issued by the Indian Income Tax Department under the supervision of the Central Board for Direct Taxes (CBDT) and it also serves as an important proof of identification.
It is also issued to foreign nationals (such as investors) subject to a valid visa, and hence a PAN card is not acceptable as proof of Indian citizenship. A PAN is necessary for filing income tax returns.</p></span>
               <br></br>
                  <p>
                    Number of days to process: <b>15-20 Days *</b> |  <b>Charges : Rs. 399/-</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                 
                </Col>
                <Col lg='3'><img src={ServiceImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}