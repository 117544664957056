import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";


export default function About() {
  
  return (
    <>
   <Row style={{marginTop:'11%'}}>
        <Col>
          <Container className='sectionAbout' fluid>
       
            <Row>
              <Col>
                <h1>Mumbai's TOP 10 Business Registration Company.</h1>
                <p>We take that pride with the smiles and wishes from our Happy Clients!
                The services we provide are all because of our experience and quick turnaround capability.
We have served many happy clients with good positive reviews on the Google.</p>
                <br></br>

                <p>Our important aim is to assist new Business to start smoothly.<br></br> We have experience in legal documentation and it helps us to quickly get things don for you.</p>
              <br></br><br></br>
              </Col>

            </Row>
           

          </Container>
        </Col>

      </Row>

    </>
  );
}