import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import GSTImg from '../media/rcmc.png';
import {
  Link
} from "react-router-dom";

export default function RCMC() {


    window.scrollTo(0,0);

  return (
    <>
      <Container lg='12' fluid >
        <Row>

          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>

              <Row >
                <Col lg='9'>
                    <h2>RCMC Certificate</h2>
                  <span class="headingCommon">Everything you need to know about RCMC (Registration Cum Membership Certificate). We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>RCMC (Registration Cum Membership Certificate) is a membership certificate issued for 5 years by Export Promotional Councils or commodity board of India. This certificate is issued as proof that any particular exporter is registered with the council.</p></span>
                  <p className="spacer"></p>
                  <p>
                    Number of days to process: <b> -  *</b> |  <b>Charges : -</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                  <br></br>
                  
                </Col>
                <Col lg='3'><img src={GSTImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}