import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Card from 'react-bootstrap/Card';
import top10 from '../media/top10.png';
import {
  Link
} from "react-router-dom";
/*import banner from '../media/BC-Banner.gif';*/

export default function Services() {
  window.scrollTo(300, 300);
  return (
    <>
      <Row className="serviceTop10">
        <Col>
          <img src={top10} alt="Top10" width='100%' />
        </Col>
      </Row>
      <Row>
        <Col>
          <Container className='sectionServices' fluid>

            <Row>

              <Col>
                <p>We take that pride with the smiles and wishes from our Happy Clients!
                The services we provide are all because of our experience and quick turnaround capability.
We have served many happy clients with good positive reviews on the Google.</p>
                <br></br>

                <p>We offer services at budget price. We understand the Startup companies requirements and processes. <br></br>Please click on the below services to know more about it.</p>
              </Col>

            </Row>
            <div className="spacer"></div>
            <Row style={{ display: 'inlineFlex' }} className="gridMob">


              <Col lg='4'>  <Link to="/GSTInformation" style={{ margin: '0 auto' }} >

                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>GST <br></br><span className="smallText">(Goods and Services Tax) </span><br></br><span className="smallText"> Duration : 4 to 5 Days</span></Card.Header>

                </Card>
              </Link>
                <br></br>
              </Col>

              <Col  lg='4'> <Link to="/ShopAct" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>Shop Act<br></br><span className="smallText">(Gumasta) </span><br></br><span className="smallText"> Duration : 30 Mins</span></Card.Header>

                </Card>
              </Link>
                <br></br>
              </Col>

              <Col  lg='4'><Link to="/UdyamAadhar" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>Udyam Aadhar <h5>MSME</h5>
                    <span className="smallText"> Duration : 4 to 5 Days</span></Card.Header>

                </Card>
              </Link>
                <br></br>
              </Col>

              <Col  lg='4'><Link to="/TradeRegistration" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>Trademark Registration ® ™
                  <span className="smallText"> Duration : 4 to 5 Days</span></Card.Header>
                </Card>
              </Link>
                <br></br>
              </Col>

              <Col  lg='4'><Link to="/PF" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>PF and ESIC
                  <span className="smallText"> <br></br>Duration : 2 Days</span></Card.Header>
                </Card>
              </Link>

                <br></br>
              </Col>

              <Col  lg='4'>
                <Link to="/PTax" style={{ margin: '0 auto' }} >
                  <Card border="light" style={{ width: '15rem' }}>
                    <Card.Header className='cardHeader'>Professional Tax for Employee
                  <span className="smallText"> <br></br>Duration : 4 Days</span></Card.Header>
                  </Card>
                </Link>
                <br></br>
              </Col>




              <Col  lg='4'>
              <Link to="/FSSAI" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>FSSAI Basic License
                  <span className="smallText"> <br></br>State and Central as per Category Duration : 1 to 3 Months</span></Card.Header>
                </Card>
                </Link>
                <br></br>
              </Col>

              <Col  lg='4'>
              <Link to="/PanCard" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>PAN Card
                  <span className="smallText"> <br></br>Duration : 15 Days</span></Card.Header>
                </Card>
                </Link>
                <br></br>
              </Col>

              <Col  lg='4'>
              <Link to="/ImportExport" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>Apply for Import Export Code
                </Card.Header>
                </Card>
                </Link>
                <br></br>
              </Col>

              <Col  lg='4'>
              <Link to="/RCMC" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>RCMC Certificate
                  <span className="smallText"> <br></br>Duration : Depends on system queue.</span></Card.Header>            
                </Card>
                </Link>
                <br></br>
              </Col>




              <Col  lg='4'>
              <Link to="/PassPort" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>Apply for Passport
                  <span className="smallText"> <br></br>Standard time taken to issue passport in Mumbai</span>
                  </Card.Header>
                </Card>
                </Link>
                <br></br>
              </Col>

              <Col  lg='4'>
              <Link to="/FirmDeed" style={{ margin: '0 auto' }} >
                <Card border="light" style={{ width: '15rem' }}>
                  <Card.Header className='cardHeader'>Apply for Partnership Firm/Deed
                  <span className="smallText"> <br></br>Mumbai</span></Card.Header>
                </Card>
                </Link>
                <br></br>
              </Col>
              <Col  lg='4'>
              </Col>
              <Col  lg='4'>

              </Col>
            </Row>

          </Container>
        </Col>

      </Row>



    </>
  );
}