import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ServiceImg from '../media/trade.png';
import {
    Link
} from "react-router-dom";

export default function TradeRegistration() {
    window.scrollTo(0, 0);
    return (
        <>
            <Container lg='12' fluid >
                <Row>

                    <Col>
                        <Container lg='12' className="serviceGST" fluid>
                            <Row>
                                <Col>
                                    <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg='9'>
                                    <h2>Trademark Registration</h2>
                                    <span class="headingCommon">Everything you need to know about Trademark Registration. We will share all the information with you.</span><br></br>
                                    <span className="smallText"> <p>Trademark Registration helps to protect your business name and keep it unique. Trademark can be anything related to your business. Such as logo, name, color combination, or anything which you want to claim and protect from further use by anyone else. Investment in the protection of business and securing it to keep it to yourself is the first step while you start your business.  Protect your brand today and call us for more information.</p></span>
                                    <p className="spacer"></p>
                                    <p>
                                        Number of days to process: <b>4-5 Days *</b> |  <b>Charges : Rs.799/-</b>
                                        <p> <b>* Once we receive the information from the client.</b></p>

                                    </p>
                                    <br></br>
                                  
                                </Col>
                                <Col lg='3'><img src={ServiceImg} alt="GST Registration" /></Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>

            </Container>

        </>
    );
}