import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import GSTImg from '../media/pt.png';
import {
    Link
} from "react-router-dom";

export default function PTax() {


    window.scrollTo(0, 0);

    return (
        <>
            <Container lg='12' fluid >
                <Row>

                    <Col>
                        <Container lg='12' className="serviceGST" fluid>
                            <Row>
                                <Col>
                                    <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg='9'>
                                    <h2>Professional Tax</h2>
                                    <span class="headingCommon">Everything you need to know about Professional Tax. We will share all the information with you.</span><br></br>
                                    <span className="smallText"> <p>Profession tax is the tax collected by the state governments in India. It is an indirect tax. A person earning an income from salary or anyone practicing a profession such as chartered accountant, company secretary, lawyer, doctor, etc. are required to pay this professional tax. </p></span>
                                    <p className="spacer"></p>
                                    <p>
                                        Number of days to process: <b>4-5 Days *</b> |  <b>Charges : Rs. 1799/-</b>
                                        <p> <b>* Once we receive the information from the client.</b></p>

                                    </p>
                                    <br></br>
                                   
                                </Col>
                                <Col lg='3'><img src={GSTImg} alt="GST Registration" /></Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>

            </Container>

        </>
    );
} 