import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import GSTImg from '../media/passport.png';
import {
  Link
} from "react-router-dom";

export default function Passport() {


    window.scrollTo(0,0);

  return (
    <>
      <Container lg='12' fluid >
        <Row>

          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>

              <Row >
                <Col lg='9'>
                    <h2>Apply for Passport</h2>
                  <span class="headingCommon">Everything you need to know about Passport issued in INDIA. We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>A passport is a travel document, usually issued by a country's government to its citizens, that certifies the identity and nationality of its holder primarily for international travel. Standard passports may contain information such as the holder's name, place and date of birth, photograph, signature, and other relevant identifying information.</p></span>
                  <p className="spacer"></p>
                  <p>
                    Number of days to process: <b>Standard time taken to issue passport in Mumbai *</b>  <br></br> <b>Charges : Rs. 2199/-</b>&nbsp;&nbsp;<b>* Once we receive the information from the client.</b>
              

                  </p>
                  <br></br>
                 
                </Col>
                <Col lg='3'><img src={GSTImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}