import React from "react";
import one from '../media/one.png';
import two from '../media/two.png';
import three from '../media/three.png';
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck  } from '@fortawesome/free-solid-svg-icons'
import {
 
    Link
  } from "react-router-dom";

import Carousel from 'react-bootstrap/Carousel';
import Review from '../media/Google_reviews.png';

export default function Home() {

    return (
        <>

            <Row className='homeSectionPos'>
                <Col>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={one}
                                alt="First slide"
                            />

                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={two}
                                alt="Third slide"
                            />


                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={three}
                                alt="Third slide"
                            />


                        </Carousel.Item>
                    </Carousel>
                </Col>

            </Row>
            <Row>
                <Col >
                    <Container className="sectionRClients" fluid >

                        <Row>
                            <Col lg='12' ><h1>Our Happy Clients! See what they have to say!</h1></Col>

                        </Row><br></br>
                        <Row>
                            <Col lg='12'><a href="https://www.google.com/search?sxsrf=ALeKk02cVkVuE1DDHRl1EsboVyp0rKZUMg%3A1613126668094&ei=DFwmYMiPBZzUrtoP_PmluAE&q=breakcomfort&oq=break&gs_lcp=Cgdnd3Mtd2l6EAMYADIECCMQJzIECAAQQzIECAAQQzIECAAQQzIECC4QQzIECAAQQzIECAAQQzIECAAQQzIFCC4QsQMyCAgAELEDEIMBOgUIABCRAjoICC4QsQMQgwFQl6aTA1jWq5MDYIa1kwNoAHACeACAAbACiAHTC5IBBTItNS4xmAEAoAEBqgEHZ3dzLXdpesABAQ&sclient=gws-wiz#lrd=0x3be7b7b9a73cfa91:0xef181b24595af0e1,1,,," target="_blank" title="BreakComfort Google Reviews" rel="noreferrer"><img src={Review} className="img-fluid imgShadow" alt="Google Review" /></a></Col>

                        </Row>


                    </Container>
                    <Container className="sectionHomeAbout" fluid>
                        <Row>
                            <Col lg='12'><h1>About BreakComfort<sup>&trade;</sup> <FontAwesomeIcon icon={faCheck} /></h1><br></br>
                                <p><h3>We are Mumbai's <span className="top10">TOP 10</span> Business Registration Company.</h3><br></br> We take that pride with the smiles and wishes from our Happy Clients! <br></br>The services we provide are all because of our experience and quick turnaround capability.<br></br> We have served many happy clients with good positive reviews on the Google.</p>

                            </Col>

                        </Row><br></br>
                        <Row>
                           

                            <Link to="/services" style={{margin:'0 auto'}} >
                            <button className="btnS" > Know more about our services </button>
                            </Link>
                        </Row>
                    </Container>

                </Col>


            </Row>




        </>
    );
}