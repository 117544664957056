import logo from './media/BreakComfort-logo-PNG.png';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'


import Home from './Pages/Home'
import Services from './Pages/Services'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Footer from './Pages/footer'
/* Services components */
import GstInformation from "./Pages/Gst-Information"
import ShopAct from "./Pages/ShopAct"
import UdyamAadhar from "./Pages/UdyamAadhar"
import TradeRegistration from "./Pages/TradeRegistration"
import PF from "./Pages/PF"
import PTax from "./Pages/PTax"
import FSSAI from './Pages/FSSAI';

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Pan from './Pages/Pan';
import ImportExport from './Pages/ImportExport';
import RCMC from './Pages/RCMC';
import Passport from './Pages/Passport';
import FirmDeed from './Pages/FirmDeed';




function App() {
  return (
    <>
      <Router>
        <Container fluid>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col lg='12' className="topNav" >We are one stop solution for all your legal Documentations | Call us Today :&nbsp; <a href="tel:919867008910" style={{color:"blue"}}>+91 9867008910</a>&nbsp; | Email us : &nbsp;<a href="mailto:ceo@breakcomfort.com?cc=sagsentrepreneur@gmail.com&subject=Enquiry"  style={{color:"blue"}}>ceo@breakcomfort.com </a> </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col className='navPos' ><Navbar bg="light" expand="lg" className="navbar-right">
              <Navbar.Brand href="/"><img src={logo} className="logo" alt="logo" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link> <Link to="/" >Home</Link></Nav.Link>
                  <Nav.Link><Link to="/services">Services</Link></Nav.Link>
                  <Nav.Link> <Link to="/about">About  </Link></Nav.Link>
                  <Nav.Link> <Link to="/contact">Contact</Link></Nav.Link>
                </Nav>

              </Navbar.Collapse>
            </Navbar></Col>
          </Row>


          <Switch>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/GSTInformation">
              <GstInformation />
            </Route>
            <Route path="/ShopAct">
              <ShopAct />
            </Route>
            <Route path="/UdyamAadhar">
              <UdyamAadhar />
            </Route>
            <Route path="/TradeRegistration">
              <TradeRegistration />
            </Route>
            <Route path="/PF">
              <PF />
            </Route>
            <Route path="/PTax">
              <PTax />
            </Route>
            <Route path="/FSSAI">
              <FSSAI />
            </Route>
            <Route path="/PanCard">
              <Pan />
            </Route>
            <Route path="/RCMC">
              <RCMC />
            </Route>
            <Route path="/ImportExport">
              <ImportExport />
            </Route>
            <Route path="/Passport">
              <Passport />
            </Route>
            <Route path="/FirmDeed">
              <FirmDeed />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer />
        </Container>
      </Router>
    </>


  );
}










export default App;
