import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ServiceImg from '../media/ShopAct.png';
import {
  Link
} from "react-router-dom";

export default function ShopAct() {
  window.scrollTo(0, 0);
  return (
    <>
      <Container lg='12' fluid >
        <Row>
          <Col>
            <Container lg='12' className="serviceGST" fluid>
            <Row>
                <Col>
                  <div><Link to="/services" style={{ margin: '0 auto' }} >  Back to Services</Link></div><br></br>
                </Col>
              </Row>

              <Row >
                <Col lg='9'>
                  <h2>Shop Act</h2>
                  <span class="headingCommon">Everything you need to know about ShopAct. We will share all the information with you.</span><br></br>
                  <span className="smallText"> <p>Shop Act or we also call it as Gumasta License is a registration required for doing any kind of business in the state of Maharashtra. It is governed by the Municipal Corporation of Mumbai under the Maharashtra Shops and Establishment Act. This is a certificate which provides you the authority to do your business at a particular place.</p></span>
                  <p className="spacer"></p>
                  <p>
                    Number of days to process: <b>30 mins *</b> |  <b>Charges : Rs. 399/-</b>
                    <p> <b>* Once we receive the information from the client.</b></p>

                  </p>
                  <br></br>
                 
                </Col>
                <Col lg='3'><img src={ServiceImg} alt="GST Registration" /></Col>
              </Row>
            </Container>
          </Col>

        </Row>

      </Container>

    </>
  );
}